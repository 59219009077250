import { useUserStore } from '../stores/useUserStore'

export interface UseKycLevelReturn {
  kycLevel: Ref<number>
  kycLevelName: Ref<string>
}

export function useKycLevel(): UseKycLevelReturn {
  const { t } = useI18n()
  const { user } = storeToRefs(useUserStore())

  const kycLevel = computed(() => user.value?.kycLevel ?? 0)
  const kycLevelName = computed(() =>
    user.value?.kycLevel
      ? t('verification.level', { level: user.value?.kycLevel })
      : t('verification.base'),
  )

  return {
    kycLevel,
    kycLevelName,
  }
}
