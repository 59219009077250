import { throttle } from '@st/utils'
import { useUserStore } from '@st/user/stores/useUserStore'

/**
 * Этот стор лежит именно тут, поскольку его
 * переиспользование в других компонентах не предполагается.
 * В дальнейшем возможно каутнеры нужно будет тянуть из других сторов
 */
export const useDashboardCountersStore = defineStore(
  'dashboardCounters',
  () => {
    const { data: depositFetchedCounter, refresh: updateDepositCounter } =
      useStFetch('/account/transaction/find', {
        method: 'post',
        body: {
          params: {
            operationType: ['deposit'],
            notInTerminalStatus: true,
          },
          pagination: {
            perPage: 1,
            page: 1,
            orderBy: [
              {
                sortOrder: 'ASC',
                fieldName: 'id',
              },
            ],
          },
        },
        transform: (response) => response.paging.total ?? 0,
        immediate: false,
      })

    const { data: withdrawalFetchedCounter, refresh: updateWithdrawalCounter } =
      useStFetch('/account/transaction/find', {
        method: 'post',
        body: {
          params: {
            operationType: ['withdrawal'],
            notInTerminalStatus: true,
          },
          pagination: {
            perPage: 1,
            page: 1,
            orderBy: [
              {
                sortOrder: 'ASC',
                fieldName: 'id',
              },
            ],
          },
        },
        transform: (response) => response.paging.total ?? 0,
        immediate: false,
      })

    const { isAuthenticated } = storeToRefs(useUserStore())
    const depositCounter = ref(0)
    const withdrawalCounter = ref(0)

    async function updateCounters() {
      if (isAuthenticated.value) {
        await updateWithdrawalCounter()
        await updateDepositCounter()
        depositCounter.value = depositFetchedCounter.value ?? 0
        withdrawalCounter.value = withdrawalFetchedCounter.value ?? 0
      } else {
        depositCounter.value = 0
        withdrawalCounter.value = 0
      }
    }

    const updateCountersWithThrottle = throttle(updateCounters, 1000)

    const io = useSocket()
    io.on('depositCreated', updateCountersWithThrottle)
    io.on('depositStatus', updateCountersWithThrottle)
    io.on('withdrawalStatus', updateCountersWithThrottle)
    io.on('accountBalance', updateCountersWithThrottle)

    /**
     * запрашивает каунтеры при наличии юзера
     * сбрасывает в 0 при отсутствии
     */
    watch(
      () => isAuthenticated.value,
      () => {
        updateCounters()
      },
      { immediate: true },
    )

    return {
      withdrawalCounter,
      depositCounter,
    }
  },
)
